import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import {Trans, plural, t} from '@lingui/macro';
import {formatLocaleDate, formatUrlQueryParams, initDashboardChartData} from '@zentact/common';
import {
  BaseTheme,
  Breadcrumbs,
  DashboardDatepicker,
  TransactionsChart,
  Typography,
  cn,
  getDashboardDatepickerDefaults,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {Link} from 'react-router-dom';
import {z} from 'zod';
import {Customize} from './customize';
import {LatestPaymentsList} from './latest-payments-list/latest-payments-list';
import {Summary, SummaryItemDataType, SummaryItemPropType} from './summary';

type ChartAxesDataType = [number[], string[]];

export const DashboardPage = () => {
  const {tenant, organization, currency} = useStore();
  const {locale} = useLocale();
  const primaryColor = tenant?.brandConfiguration?.primaryColorHex || BaseTheme.primary;

  const dashboardSearchParamsSchema = z.object({
    startDate: z
      .date()
      .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
      .nullable()
      .optional(),
    endDate: z
      .date()
      .or(
        z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
      )
      .nullable()
      .optional(),
  });

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    dashboardSearchParamsSchema
  );

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;
  const dateValue = startDate && endDate ? {startDate, endDate} : getDashboardDatepickerDefaults();

  const {data: summaryData, isLoading: summaryIsLoading} =
    trpc.payment.organizationPaymentSummary.useQuery({
      fromDate: dateValue.startDate,
      toDate: dateValue.endDate,
    });

  const {data: transactionsData, isLoading: transactionsIsLoading} =
    trpc.payment.paymentVolumeByDates.useQuery({
      fromDate: dateValue.startDate,
      toDate: dateValue.endDate,
      timezone: tz,
    });

  const achChargebacksCount = summaryData?.achChargebacksCount ?? 0;
  const cardChargebackCount = summaryData?.cardChargebackCount ?? 0;

  const summaryItems: SummaryItemPropType[] = [
    {
      title: t`Volume`,
      value: summaryData?.totalVolume,
      type: SummaryItemDataType.CURRENCY,
    },
    {
      title: t`Payments`,
      value: summaryData?.totalPaymentsCount || 0,
      type: SummaryItemDataType.NUMBER,
    },
    {
      title:
        achChargebacksCount + cardChargebackCount > 0 ? (
          <div className="md:text-center">
            <Link
              to={`${RoutePath.DISPUTES}?${formatUrlQueryParams({
                startDate: dateValue.startDate,
                endDate: dateValue.endDate,
                type: 'CHARGEBACK',
                status: ['CHARGEBACK', 'SECOND_CHARGEBACK'],
              })}`}
              className="text-sm text-primary-600"
            >
              {achChargebacksCount + cardChargebackCount}
            </Link>{' '}
            {achChargebacksCount && cardChargebackCount
              ? t`Chargebacks (${cardChargebackCount} Cards, ${achChargebacksCount} ACH)`
              : cardChargebackCount
                ? plural(cardChargebackCount, {
                    one: 'Credit Card Chargeback',
                    other: 'Credit Card Chargebacks',
                  })
                : plural(achChargebacksCount, {
                    one: 'ACH Chargeback',
                    other: 'ACH Chargebacks',
                  })}
          </div>
        ) : (
          <Trans>0 Chargebacks</Trans>
        ),
      value: summaryData?.totalChargebacksVolume || 0,
      type: SummaryItemDataType.CURRENCY,
    },
    {
      title: summaryData?.refundsCount ? (
        <div className="flex gap-1">
          <Link
            to={`${RoutePath.PAYMENTS}?${formatUrlQueryParams({
              startDate: dateValue.startDate,
              endDate: dateValue.endDate,
              dateRangeFilterBy: 'refund',
            })}`}
            className="text-sm text-primary-600"
          >
            {summaryData.refundsCount}
          </Link>
          {plural(summaryData.refundsCount, {one: 'Refund', other: 'Refunds'})}
        </div>
      ) : (
        <Trans>0 Refunds</Trans>
      ),
      value: summaryData?.totalRefundedVolume,
      type: SummaryItemDataType.CURRENCY,
    },
    {
      title: t`Average Transaction`,
      value: summaryData?.averageVolume,
      type: SummaryItemDataType.CURRENCY,
    },
  ];

  const [dataY, dataX] = transactionsData?.rows?.reduce(
    (acc, {volume, date}) => {
      acc[0].push(volume);
      acc[1].push(date);
      return acc;
    },
    [[], []] as ChartAxesDataType
  ) || [[], []];

  const chartData = initDashboardChartData({
    title: t`Transactions`,
    dataX,
    dataY,
    primaryColor,
    currency,
    locale,
  });

  const latestPaymentsList = trpc.payment.payments.useQuery(
    {
      pageIndex: 0,
      pageSize: 5,
      orderBy: {createdAt: 'desc'},
    },
    {keepPreviousData: true}
  );

  const handleDateChange = (dateRange: {startDate: Date; endDate: Date}) => {
    setTypedSearchParams(dateRange);
  };

  return (
    <>
      <Breadcrumbs currentDashboard={true} />
      <div className="flex flex-col items-center justify-between pt-4 sm:flex-row">
        <div className="w-full">
          <Typography variant="header-page">
            <Trans>For</Trans> {formatLocaleDate(dateValue.startDate, 'short')}
            {' - '}
            {formatLocaleDate(dateValue.endDate, 'short')}
          </Typography>
        </div>
        <div className="w-full mt-4 shrink-0 sm:mt-0 sm:w-64">
          <DashboardDatepicker dateRange={dateValue} onChange={handleDateChange} />
        </div>
      </div>
      <Summary
        summaryItems={summaryItems}
        isLoading={summaryIsLoading}
        locale={locale}
        currency={currency}
      />
      {/* We have to re-trigger the rendering by passing a dynamic "key" property otherwise 
        React does shallow object comparison and deeply nested data in chartData object does not trigger a re-render.
        See ZEN-1258
      */}
      <TransactionsChart
        key={`chart-currency-${currency}`}
        chartData={chartData}
        isLoading={transactionsIsLoading}
      />
      <div
        className={cn(
          !organization?.brandConfiguration.logoUrl ? 'grid grid-cols-1' : '',
          'gap-0 mt-5 sm:grid-cols-3 sm:gap-4'
        )}
      >
        {!organization?.brandConfiguration.logoUrl && <Customize />}
        <br className="sm:hidden" />
        <div className="col-span-1 overflow-x-auto bg-white rounded-lg shadow sm:col-span-2">
          <div className="flex items-center justify-between px-4 py-5 sm:px-6">
            <Typography variant="header-section">
              <Trans>Latest Payments</Trans>
            </Typography>
            <Link className="text-sm text-primary-600" to={RoutePath.PAYMENTS}>
              <Trans>See all</Trans>
            </Link>
          </div>
          <div className="w-full">
            <LatestPaymentsList
              latestPaymentsList={latestPaymentsList.data}
              isLoading={
                latestPaymentsList.isLoading ||
                (latestPaymentsList.isRefetching && latestPaymentsList.isPreviousData)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
