import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {i18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {
  ExtendedPaymentMethodEnumDisplayMap,
  ShopperInteractionPublicEnum,
  formatAmount,
  formatAmountWithoutCurrency,
  shopperInteractionLabels,
} from '@zentact/common';
import {
  Button,
  ContentCard,
  InputCheckbox,
  Loading,
  SanitizeHTML,
  Typography,
  ValidationError,
} from '@zentact/ui-tailwind';
import {cn} from '@zentact/ui-tailwind/utils';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import z from 'zod';
import {trpc} from '../../../../api/trpcClient';
import {merchantRegistrationStepTitles} from '../../constants';

const schema = z.object({
  agree: z.custom(data => Boolean(data), 'To proceed you must agree to terms and condition'),
});

type TermsAndConditionsForm = z.infer<typeof schema>;

type Props = {
  onComplete: () => void;
  registrationSessionId: string;
};

export const TermsAndConditions = ({onComplete, registrationSessionId}: Props) => {
  const navigate = useNavigate();
  const {data: splitConfiguration, isLoading: isSplitConfigurationLoading} =
    trpc.merchantRegistration.getSplitConfiguration.useQuery(
      {
        registrationSessionId,
      },
      {
        onError: () => navigate('/error'),
      }
    );
  const {data: tenantIntervalFeeGroup, isLoading: isTenantIntervalFeeGroupLoading} =
    trpc.merchantRegistration.getMerchantTenantIntervalFeeGroup.useQuery(
      {
        registrationSessionId,
      },
      {
        onError: () => navigate('/error'),
      }
    );
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<TermsAndConditionsForm>({
    resolver: zodResolver(schema),
  });

  const {tenant} = useStore();
  const {locale} = useLocale();

  const signUpMutation = trpc.merchantRegistration.step2AgreeTermsAndConditions.useMutation({
    onSuccess() {
      onComplete();
    },
  });

  const {data: termsAndConditionsData} = trpc.merchantRegistration.getTermsAndConditions.useQuery(
    {},
    {keepPreviousData: true}
  );

  const onSubmit = (data: TermsAndConditionsForm) => {
    signUpMutation.mutate({
      agree: data.agree,
      registrationSessionId,
    });
  };

  if (
    !tenant ||
    isSplitConfigurationLoading ||
    isTenantIntervalFeeGroupLoading ||
    !termsAndConditionsData
  ) {
    return <Loading />;
  }

  if (!splitConfiguration) {
    console.error("Tenant doesn't have split configuration");
    return <Loading />;
  }

  const processingFeesHtml = (
    <>
      <Trans>
        <b>Processing Cost</b>
      </Trans>
      <ul className="mt-5 ml-5 list-disc">
        {splitConfiguration.splitRules
          .sort((a, b) => {
            if (
              a.paymentMethod === 'ANY' &&
              a.shopperInteraction === ShopperInteractionPublicEnum.Any
            )
              return 1;
            if (
              b.paymentMethod === 'ANY' &&
              b.shopperInteraction === ShopperInteractionPublicEnum.Any
            )
              return -1;

            if (a.paymentMethod === 'ANY') return 1;
            if (b.paymentMethod === 'ANY') return -1;

            if (a.paymentMethod === b.paymentMethod) {
              return a.shopperInteraction === ShopperInteractionPublicEnum.Any
                ? 1
                : b.shopperInteraction === ShopperInteractionPublicEnum.Any
                  ? -1
                  : 0;
            }

            return a.paymentMethod.localeCompare(b.paymentMethod);
          })
          .map(({paymentMethod, shopperInteraction, splitPercentage, splitFixed, pricingModel}) => {
            const feeText = [
              pricingModel === 'COST_PLUS' && splitPercentage
                ? `variable processing fee (at cost) plus a markup of ${formatAmountWithoutCurrency(
                    splitPercentage,
                    locale,
                    splitConfiguration.currency
                  )}% (${splitPercentage} basis points)`
                : '',
              pricingModel === 'FLAT' && splitPercentage
                ? `flat ${formatAmountWithoutCurrency(
                    splitPercentage,
                    locale,
                    splitConfiguration.currency
                  )}%`
                : '',
              splitFixed
                ? `fixed ${formatAmount(
                    splitFixed,
                    locale,
                    splitConfiguration.currency
                  )} fee per transaction`
                : '',
            ]
              .filter(Boolean)
              .join(' + ');

            return (
              <li key={`${paymentMethod}-${shopperInteraction}`} className="">
                {paymentMethod === 'ANY'
                  ? `All other payments${
                      shopperInteraction !== ShopperInteractionPublicEnum.Any
                        ? ` via ${shopperInteractionLabels[shopperInteraction].split(' – ')[1]}`
                        : ''
                    } are subject to ${feeText}.`
                  : `${
                      ExtendedPaymentMethodEnumDisplayMap[paymentMethod]
                    } Processing Cost is ${feeText} ${
                      shopperInteraction === ShopperInteractionPublicEnum.Any
                        ? splitConfiguration.splitRules.filter(
                            rule =>
                              rule.paymentMethod === paymentMethod &&
                              rule.shopperInteraction !== ShopperInteractionPublicEnum.Any
                          ).length >= 1
                          ? 'for all other entry types.'
                          : ''
                        : `for payments via ${
                            shopperInteractionLabels[shopperInteraction].split(' – ')[1]
                          }.`
                    }`}
              </li>
            );
          })}
      </ul>
    </>
  );

  return (
    <div className="flex flex-col gap-y-4">
      {!!termsAndConditionsData.termsHtml && (
        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <Typography as="h3" variant="header-lg">
              {i18n._(merchantRegistrationStepTitles.step2)}
            </Typography>
          </div>
          <div className="px-4 py-6 text-sm text-gray-800 sm:p-8 max-h-[400px] overflow-y-auto">
            <SanitizeHTML html={termsAndConditionsData.termsHtml} />
          </div>
        </div>
      )}
      <form
        className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <Typography as="h3" variant="header-lg">
            <Trans>Agree to Processing Fees</Trans>
          </Typography>
        </div>
        <div className="flex flex-col gap-8 px-4 py-6 sm:p-8">
          {splitConfiguration.overrideFeeSectionContent ? (
            <SanitizeHTML html={splitConfiguration.overrideFeeSectionContent} />
          ) : (
            <ContentCard
              childrenWrapperClassName={cn('flex max-h-104 flex-col overflow-y-auto')}
              type="warn"
            >
              <p>
                <ul>
                  {processingFeesHtml}
                  {!!tenantIntervalFeeGroup?.perDeviceFee && (
                    <li className="mt-6">
                      <Trans>
                        <b>Per Device Fee</b>
                        <br />
                        <br />A{' '}
                        {formatAmount(
                          tenantIntervalFeeGroup.perDeviceFee,
                          locale,
                          tenantIntervalFeeGroup.currency
                        )}{' '}
                        monthly fee will be charged per terminal or other device assigned to a
                        merchant.
                      </Trans>
                    </li>
                  )}
                  {tenantIntervalFeeGroup?.tenantIntervalFees?.length && (
                    <>
                      {tenantIntervalFeeGroup.tenantIntervalFees.some(
                        fee => fee.period === 'MONTHLY'
                      ) && (
                        <li className="mt-6">
                          <Trans>
                            <b>Monthly Fees (Collected 1st Business day of following month):</b>
                            <br />
                            <br />
                            <ul className="ml-5 list-disc">
                              {tenantIntervalFeeGroup.tenantIntervalFees
                                .filter(fee => fee.period === 'MONTHLY')
                                .map(fee => (
                                  <li key={fee.name || fee.amount}>
                                    {fee.name && <span>{fee.name}</span>}
                                    <span className="ml-4">
                                      {formatAmount(
                                        fee.amount,
                                        locale,
                                        tenantIntervalFeeGroup.currency
                                      )}
                                    </span>
                                  </li>
                                ))}
                            </ul>
                          </Trans>
                        </li>
                      )}

                      {tenantIntervalFeeGroup.tenantIntervalFees.some(
                        fee => fee.period === 'ANNUAL'
                      ) && (
                        <li className="mt-6">
                          <Trans>
                            <b>Annual Fees:</b>
                            <br />
                            <br />
                            <ul className="ml-5 list-disc">
                              {tenantIntervalFeeGroup.tenantIntervalFees
                                .filter(fee => fee.period === 'ANNUAL')
                                .map(fee => (
                                  <li key={fee.name || fee.amount}>
                                    {fee.name && <span>{fee.name}</span>}
                                    {fee.annualFeeCollectMonth !== null &&
                                      fee.annualFeeCollectMonth !== undefined && (
                                        <span>
                                          {' '}
                                          (Collected 1st Business day of{' '}
                                          {new Date(0, fee.annualFeeCollectMonth).toLocaleString(
                                            locale,
                                            {month: 'long'}
                                          )}
                                          )
                                        </span>
                                      )}
                                    <span className="ml-4">
                                      {formatAmount(
                                        fee.amount,
                                        locale,
                                        tenantIntervalFeeGroup.currency
                                      )}
                                    </span>
                                  </li>
                                ))}
                            </ul>
                          </Trans>
                        </li>
                      )}
                    </>
                  )}
                  {splitConfiguration.chargebackFeesModel === 'MERCHANT_PAY' ||
                  splitConfiguration.additionalChargebackFee ? (
                    <li className="mt-6">
                      <Trans>
                        <b>
                          Chargeback{' '}
                          {splitConfiguration.chargebackFeesModel === 'MERCHANT_PAY' &&
                          splitConfiguration.additionalChargebackFee
                            ? 'Fees'
                            : 'Fee'}
                          :
                        </b>{' '}
                        {splitConfiguration.chargebackFeesModel === 'MERCHANT_PAY'
                          ? `Payment Processor Fee${
                              splitConfiguration.additionalChargebackFee
                                ? ` + Additional ${formatAmount(
                                    splitConfiguration.additionalChargebackFee,
                                    locale,
                                    splitConfiguration.currency
                                  )}`
                                : ''
                            }`
                          : formatAmount(
                              // biome-ignore lint/style/noNonNullAssertion: <explanation>
                              splitConfiguration.additionalChargebackFee!,
                              locale,
                              splitConfiguration.currency
                            )}
                      </Trans>
                    </li>
                  ) : null}

                  {splitConfiguration.refundFeesModel === 'MERCHANT_PAY' ||
                  splitConfiguration.additionalRefundFee ? (
                    <li className="mt-6">
                      <Trans>
                        <b>
                          Refund{' '}
                          {splitConfiguration.refundFeesModel === 'MERCHANT_PAY' &&
                          splitConfiguration.additionalRefundFee
                            ? 'Fees'
                            : 'Fee'}
                          :
                        </b>{' '}
                        {splitConfiguration.refundFeesModel === 'MERCHANT_PAY'
                          ? `Payment Processor Fee${
                              splitConfiguration.additionalRefundFee
                                ? ` + Additional ${formatAmount(
                                    splitConfiguration.additionalRefundFee,
                                    locale,
                                    splitConfiguration.currency
                                  )}`
                                : ''
                            }`
                          : formatAmount(
                              // biome-ignore lint/style/noNonNullAssertion: <explanation>
                              splitConfiguration.additionalRefundFee!,
                              locale,
                              splitConfiguration.currency
                            )}
                      </Trans>
                    </li>
                  ) : null}
                </ul>
              </p>
            </ContentCard>
          )}
          <div className="flex flex-col gap-2">
            <InputCheckbox {...register('agree')}>
              {termsAndConditionsData.termsHtml ? (
                <Trans>
                  I hereby agree to the terms and conditions and consent to the processing fees as
                  outlined above.
                </Trans>
              ) : (
                <Trans>I hereby agree to the processing fees as outlined above.</Trans>
              )}
            </InputCheckbox>
            <ValidationError isVisible={Boolean(errors.agree)}>
              {errors.agree?.message?.toString()}
            </ValidationError>
          </div>
        </div>
        <div className="flex items-center justify-end p-4 gap-x-6 sm:px-8">
          <ValidationError isVisible={signUpMutation.isError}>
            {signUpMutation?.error?.message}
          </ValidationError>
          <Button
            disabled={signUpMutation.isLoading}
            isLoading={signUpMutation.isLoading}
            className="w-full"
            size="xl"
            type="submit"
          >
            <Trans>I agree</Trans>
          </Button>
        </div>
      </form>
    </div>
  );
};
