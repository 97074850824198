import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {Trans, t} from '@lingui/macro';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {DropDownMinimalMenuIcon, useNotification} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

enum MerchantActions {
  DEACTIVATE = 'deactivate',
  RESUME = 'resume',
  UPDATE_CUSTOMER_SUPPORT = 'updateCustomerSupport',
  UPDATE_EMAIL_NOTIFICATIONS = 'updateEmailNotififications',
  UPDATE_SURCHARGE_CONFIGURATION = 'updateSurchargeConfiguration',
  MANAGE_BANK_ACCOUNT = 'manageBankAccount',
  DETAILS = 'details',
  OPEN_HOP = 'openHop',
}

const statusToItemsMap = (
  action: (action: MerchantActions) => void,
  allowResume: boolean,
  openDetails?: (row: MerchantAccountsListItem | null) => void,
  // not passed when the merchant is not "active" or if the context user is not an organization admin
  openUpdateSurchargeConfigurationPanel?: (row: MerchantAccountsListItem | null) => void,
  isOrganizationAdmin?: boolean
): Record<
  MerchantAccountPublicStatus,
  {name: string; onClick: () => void; itemClassName?: string}[]
> => ({
  [MerchantAccountPublicStatus.ACTIVE]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    ...(isOrganizationAdmin
      ? [
          {
            name: t`Manage Bank Accounts`,
            onClick: () => action(MerchantActions.MANAGE_BANK_ACCOUNT),
          },
        ]
      : []),
    {
      name: t`Update Customer Support`,
      onClick: () => action(MerchantActions.UPDATE_CUSTOMER_SUPPORT),
    },
    {
      name: t`Update Email Notifications`,
      onClick: () => action(MerchantActions.UPDATE_EMAIL_NOTIFICATIONS),
    },
    ...(openUpdateSurchargeConfigurationPanel
      ? [
          {
            name: t`Update Surcharge Configuration`,
            onClick: () => action(MerchantActions.UPDATE_SURCHARGE_CONFIGURATION),
          },
        ]
      : []),
  ],
  [MerchantAccountPublicStatus.ACTIVE_ALLOWED_APP_CHANGES]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    ...(isOrganizationAdmin
      ? [
          {
            name: t`Manage Bank Accounts`,
            onClick: () => action(MerchantActions.MANAGE_BANK_ACCOUNT),
          },
        ]
      : []),
    {
      name: t`Update Customer Support`,
      onClick: () => action(MerchantActions.UPDATE_CUSTOMER_SUPPORT),
    },
    {
      name: t`Update Email Notifications`,
      onClick: () => action(MerchantActions.UPDATE_EMAIL_NOTIFICATIONS),
    },
    ...(openUpdateSurchargeConfigurationPanel
      ? [
          {
            name: t`Update Surcharge Configuration`,
            onClick: () => action(MerchantActions.UPDATE_SURCHARGE_CONFIGURATION),
          },
        ]
      : []),
    ...(isOrganizationAdmin
      ? [{name: t`Update Merchant Application`, onClick: () => action(MerchantActions.OPEN_HOP)}]
      : []),
  ],
  [MerchantAccountPublicStatus.REJECTED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    ...(allowResume ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}] : []),
  ],
  [MerchantAccountPublicStatus.INVITE_ACCEPTED]: [
    ...(allowResume ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}] : []),
  ],
  [MerchantAccountPublicStatus.APPLICATION_IN_PROGRESS]: [
    ...(allowResume ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}] : []),
  ],
  [MerchantAccountPublicStatus.APPLICATION_IN_REVIEW]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.DEACTIVATED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.INVITED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    ...(allowResume ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}] : []),
  ],
  [MerchantAccountPublicStatus.INITIATED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    ...(allowResume ? [{name: t`Resume`, onClick: () => action(MerchantActions.RESUME)}] : []),
  ],
  [MerchantAccountPublicStatus.INVITE_EXPIRED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.INVITE_REVOKED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.APPLICATION_REVOKED]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.NEEDS_ORGANIZATION]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.ASSIGNING_ORGANIZATION]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.CLOSING]: [
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
  ],
  [MerchantAccountPublicStatus.ADDITIONAL_DATA_REQUIRED]: [
    {name: t`Review Application`, onClick: () => action(MerchantActions.OPEN_HOP)},
    ...(openDetails ? [{name: t`Details`, onClick: () => action(MerchantActions.DETAILS)}] : []),
    {name: t`Manage Bank Accounts`, onClick: () => action(MerchantActions.MANAGE_BANK_ACCOUNT)},
  ],
});

export type MerchantAccountActionsProps = {
  merchantAccountRow: MerchantAccountsListItem | MerchantAccountDetailsOutput;
  registrationSessionId: string;
  userId: string | null;
  openDetails?: (row: MerchantAccountsListItem | null) => void;
  openUpdateCustomerSupportPanel: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  openUpdateEmailNotificationsPanel: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  openUpdateBankAccountPanel: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  openUpdateSurchargeConfigurationPanel?: (
    // not passed when the merchant is not "active" or if the context user is not an organization admin
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  viewType: 'dots-button' | 'chevron-button';
};

export const MerchantAccountActions = ({
  merchantAccountRow,
  registrationSessionId,
  userId,
  openDetails,
  openUpdateCustomerSupportPanel,
  openUpdateEmailNotificationsPanel,
  openUpdateBankAccountPanel,
  openUpdateSurchargeConfigurationPanel,
  viewType,
}: MerchantAccountActionsProps) => {
  const {user} = useAuth0();
  const {authProfile} = useStore();
  const navigate = useNavigate();
  const {showErrorNotification} = useNotification();

  const merchantAccountId =
    'merchantAccount' in merchantAccountRow
      ? merchantAccountRow.merchantAccount?.id
      : merchantAccountRow.id;

  const trpcContext = trpc.useUtils();

  const handleOpenHop = useCallback(async () => {
    try {
      const {redirectUrl} = await trpcContext.merchantRegistration.getResumeHopLink.fetch({
        merchantAccountId,
      });
      window.open(redirectUrl, '_blank');
    } catch (e) {
      showErrorNotification(t`Failed to generate a hop link`, (e as Error).message);
    }
  }, [merchantAccountId, showErrorNotification]);

  const handleAction = (action: MerchantActions) => {
    if (action === MerchantActions.UPDATE_CUSTOMER_SUPPORT && openUpdateCustomerSupportPanel) {
      openUpdateCustomerSupportPanel(merchantAccountRow);
    }
    if (action === MerchantActions.MANAGE_BANK_ACCOUNT && openUpdateBankAccountPanel) {
      openUpdateBankAccountPanel(merchantAccountRow);
    }
    if (
      action === MerchantActions.UPDATE_EMAIL_NOTIFICATIONS &&
      openUpdateEmailNotificationsPanel
    ) {
      openUpdateEmailNotificationsPanel(merchantAccountRow);
    }
    if (action === MerchantActions.DETAILS && openDetails && 'firstName' in merchantAccountRow) {
      openDetails(merchantAccountRow);
    }

    if (action === MerchantActions.RESUME) {
      navigate(`/registration/${registrationSessionId}`);
    }

    if (
      action === MerchantActions.UPDATE_SURCHARGE_CONFIGURATION &&
      openUpdateSurchargeConfigurationPanel
    ) {
      openUpdateSurchargeConfigurationPanel(merchantAccountRow);
      return;
    }

    if (action === MerchantActions.OPEN_HOP) {
      handleOpenHop();
    }
  };

  const canResume = Boolean(userId) && userId === user?.sub;

  const menuItems = statusToItemsMap(
    handleAction,
    canResume,
    openDetails,
    openUpdateSurchargeConfigurationPanel,
    authProfile.data?.isOrganizationAdmin
  )[merchantAccountRow.status];
  if (!menuItems || !menuItems.length) return null;

  return (
    <>
      <DropDownMinimalMenuIcon
        items={menuItems}
        buttonContent={viewType === 'chevron-button' ? <Trans>Actions</Trans> : undefined}
      />
    </>
  );
};
